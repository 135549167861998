import React from 'react';

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import analytics from 'core/analytics';
import { Footer as FooterWrapper } from './styles';

export default function Footer() {
  const [subDomain] = window.location.host.split('.');

  const SUPPORT_PHONE_NUMBER =
    subDomain === 'walmart' ? '707-289-3068' : '855-JYVE-777';
  const SUPPORT_EMAIL =
    subDomain === 'walmart' ? 'walmartsupport@jyve.com' : 'support@jyve.com';

  return (
    <FooterWrapper>
      <Typography variant="subtitle1" gutterBottom>
        Contact Jyve support
      </Typography>
      <Button
        href={`tel:${SUPPORT_PHONE_NUMBER}`}
        variant="contained"
        color="default"
        startIcon={<PhoneIcon color="primary" />}
        onClick={() => {
          analytics.log('track', 'walmart_help_phone_button__pressed');
        }}
      >
        {SUPPORT_PHONE_NUMBER}
      </Button>
      <Box mt={2}>
        <Button
          href={`https://support.jyve.com/hc/en-us/requests/new`}
          target="_blank"
          variant="contained"
          color="default"
          startIcon={<EmailIcon color="primary" />}
          onClick={() => {
            analytics.log('track', 'walmart_email_us_button__pressed');
          }}
        >
          Contact Us
        </Button>
      </Box>
    </FooterWrapper>
  );
}
